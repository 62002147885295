









































import { Component, Vue, Watch } from 'vue-property-decorator';
import PublicPage from '@/components/swine/layout/PublicPage.vue';
import AuthenticationForm from '@/components/general/layout/AuthenticationForm.vue';
import LoginStore from '@/store/swine/login/login.store';
import { validateEmail, validatePassword } from '@/utils/validation';
import { LoginParams } from '@/models/swine/login/login.model';

@Component({
  components: {
    PublicPage,
    AuthenticationForm,
  },
})
export default class Login extends Vue {
  title = 'Sign In';
  email = '';
  password = '';
  loginStore = LoginStore;
  areInvalidParams = false;
  errorMessage = '* Incorrect email or password';
  isButtonDisabled = false;

  created() {
    this.disableButton();
  }

  @Watch('email')
  @Watch('password')
  disableButton() {
    if (validateEmail(this.email) && validatePassword(this.password)) {
      this.isButtonDisabled = false;
    } else {
      this.isButtonDisabled = true;
    }
  }

  submitLoginForm() {
    const params: LoginParams = { email: this.email, password: this.password };
    this.loginStore.loginUser(params);
  }

  @Watch('loginStore.isLoginInvalid')
  setErrorStatus() {
    if (this.loginStore.isLoginInvalid) {
      this.areInvalidParams = true;
    } else {
      this.areInvalidParams = false;
      this.$router.push({
        name: 'ModuleSelection',
      });
    }
  }

  routeToForgotPassword() {
    this.$router.push({
      name: 'ForgotPassword',
    });
  }
}
